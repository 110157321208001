import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const apiRoot = (process.env.VUE_APP_BACKEND || '');

const { jwt } = useJwt(axios, {
	loginEndpoint: apiRoot + '/api/token/',
	registerEndpoint: apiRoot + '/api/register/',
	refreshEndpoint: apiRoot + '/api/token/refresh/',
	logoutEndpoint: apiRoot + '/api/logout/',
	tokenType: 'Bearer',
	storageTokenKeyName: 'accessToken',
	storageRefreshTokenKeyName: 'refreshToken',
})
export default jwt
