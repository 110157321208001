import { formatDistanceStrict } from 'date-fns';

let date = new Date();
let base = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999))

export default {
	namespaced: true,
	state: {
		start_date: new Date(base - 1000 * 60 * 60 * 24 * 7 + 1).toISOString(),
		end_date: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)).toISOString(),
		group_by: 'day',
		interval: 'week',
		diff: 7,
		diff_text: '7 days',
		ranges: [{ 'text': 'Час', value: 'hour' }, { 'text': 'День', value: 'day' }],
		detached: false
	},
	mutations: {
		set_start(state, value) {
			state.start_date = value
			state.diff = Math.round((new Date(state.end_date).getTime() - new Date(state.start_date).getTime()) / 60 / 60 / 24 / 1000);
			state.diff_text = formatDistanceStrict(new Date(state.end_date), new Date(state.start_date));
			state.ranges = getRanges(state.diff)
			console.log(state.ranges, state.diff)
		},
		set_end(state, value) {
			state.end_date = value
			state.diff = Math.round((new Date(state.end_date).getTime() - new Date(state.start_date).getTime()) / 60 / 60 / 24 / 1000);
			state.diff_text = formatDistanceStrict(new Date(state.end_date), new Date(state.start_date));
			state.ranges = getRanges(state.diff)
		},
		set_group(state, value) {
			state.group_by = value
		},
		set_interval(state, value) {
			state.interval = value
			state.detached = false
		},
		set_diff(state, value) {
			state.diff = value
		},
		set_detached(state, value) {
			state.detached = value
		},
	}
}

function getRanges(range) {
	console.log(range)
	switch(true) {
		case range > 380:
			return [{ 'text': 'Месяц', value: 'month' }, { 'text': 'Год', value: 'year' }]
		case range > 364:
			return [{ 'text': 'День', value: 'day' }, { 'text': 'Месяц', value: 'month' }]
		case range > 31:
			return [{ 'text': 'День', value: 'day' }, { 'text': 'Месяц', value: 'month' }]
		case range > 27:
			return [{ 'text': 'День', value: 'day' }]
		case range > 7:
			return [{ 'text': 'День', value: 'day' }]
		case range > 1:
			return [{ 'text': 'Час', value: 'hour' },{ 'text': 'День', value: 'day' }]
		}
	return [{ 'text': 'Час', value: 'hour' }]
}